<template>
  <div class="name">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>


<style lang="scss">
body {
  margin: 0;
}
</style>
