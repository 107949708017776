var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login",on:{"click":_vm.fullScreenClick}},[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.mp4Show),expression:"mp4Show"}],staticClass:"login-video",attrs:{"id":"video","src":_vm.mp4Url,"autoplay":"","muted":"","loop":""},domProps:{"muted":true}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mp4Show),expression:"!mp4Show"}],staticClass:"login-bg",attrs:{"src":_vm.bg}}),_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"code-text"},[_vm._v("发弹幕")]),_c('div',{staticClass:"login-code"},[_c('vue-qr',{attrs:{"size":_vm.size,"text":_vm.codeValue}})],1)]),_c('div',{staticClass:"detail_img"},[_c('div',{staticClass:"bottom"}),_vm._l((_vm.dmData),function(item,index){return _c('div',{key:index,staticClass:"dmGroup",style:({
          top: item.top + '%',
          animationDuration: item.time + 's',
          animationTimingFunction: 'linear',
          animationDelay: 10 + 'ms',
          display: index == 0 ? 'none' : 'inherit',
        })},[_c('div',{staticClass:"dmItem"},[_c('div',{staticClass:"dm"},[_c('div',{staticClass:"avatarBox"},[_c('img',{staticClass:"avatar",attrs:{"src":item.avatar,"mode":"aspectFit"}})]),_c('p',{staticClass:"content"},[_vm._v(_vm._s(item.desc))])])])])})],2),_c('tv-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingShow),expression:"loadingShow"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }